@import "palettes";
@import "theme";
@import "mixins";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  color: $white;
  background: $gray-800;
  box-shadow: $shadow;

  & * {
    font-family: $sans;
  }

  .logo_row {
    @include inline-flex-center;

    .logo {
      max-width: 100%;
      height: 50px;
    }

    .logo_text {
      margin: 10px;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .nav_row {
    @include inline-flex-center;
    font-size: 1rem;
    font-weight: $regular;
    text-transform: uppercase;
    letter-spacing: 1px;

    .link {
      margin: 0 10px;
    }

    &:hover .link {
      opacity: 0.25;
    }

    .link:hover {
      opacity: 1;
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  // screen width where nav bar wraps to under logo
  // adjust based on width of your lab name and nav bar
  @media (max-width: 1000px) {
    align-content: center;

    .logo_row,
    .nav_row {
      width: 100%;
    }
  }

  // screen width where logo and nav bar go from horizontal to vertical
  // adjust based on width of your lab name and nav bar
  @media (max-width: 700px) {
    .logo_row {
      flex-direction: column;
    }

    .nav_row {
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .link {
        width: 100%;
      }
    }
  }
}
